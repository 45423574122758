import { useCallback, useEffect, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, Card, CardContent, Grid, Stack, Typography } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import { UseFormReturn } from 'react-hook-form/dist/types'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { InferType, object } from 'yup'

import enTranslations from 'app/i18n/locales/en.json'
import { AttributeRow } from 'app/lib/components/AttributeRow'
import DatePickerFormField, {
  DatePickerFormFieldProps,
} from 'app/lib/components/form/DatePickerFormField'
import InputFormField, { InputFormFieldProps } from 'app/lib/components/form/InputFormField'
import SelectFormField, { SelectFormFieldProps } from 'app/lib/components/form/SelectFormField'
import { Label, LabelType } from 'app/lib/components/Label'
import { AdminAreaTrKeys, ScribePermission } from 'app/lib/constants'
import withInputFieldViewMode from 'app/lib/hoc/withInputFieldViewMode'
import { useUserPermissions } from 'app/lib/hoc/withProtectedComponent'
import {
  formatDate,
  fromISOdate,
  getEarliestActivationDate,
  isInfinity,
  today,
  toISOdate,
} from 'app/lib/utils/date'
import { getProvinceName } from 'app/lib/utils/helpers'
import {
  dateSchema,
  emailSchema,
  requiredDateSchema,
  requiredStringSchema,
} from 'app/lib/utils/yupSchemas'
import { EligibilityDirective } from 'app/models/EligibilityRecord'
import {
  EligibilityRecord,
  EligibilityRecordStatus,
  MemberIdType,
  Organization,
  OrganizationRoute,
} from 'app/models/scribe.models'
import {
  useGetAdminAreasQuery,
  useLegacyGetOrganizationQuery,
  useSubmitEligibilityDirectiveMutation,
} from 'app/redux/scribeApi'
import { getApplicationConfig } from 'config'

import { ResendEmailDialog } from './ResendEmailDialog'
import { UpdateStatusDialog } from './UpdateStatusDialog'

export enum ActionType {
  REACTIVATE = 'reactivate',
  DEACTIVATE = 'deactivate',
}

type ProfileProps = Readonly<{
  eligibilityRecord: EligibilityRecord
  organizationType: Organization['memberIdType']
  organisationName: string
}>

type RecordFieldType = keyof typeof enTranslations.eligibilityRecordPage.fields

const InputFormFieldWithViewMode = withInputFieldViewMode<InputFormFieldProps>(InputFormField)
const SelectFormFieldWithViewMode = withInputFieldViewMode<SelectFormFieldProps>(SelectFormField)
const DatePickerFormFieldWithViewMode =
  withInputFieldViewMode<DatePickerFormFieldProps>(DatePickerFormField)

const memberSchema = (
  isEmployeeIdOrg: boolean,
  activationMinDate: Date,
  isDirtyActivationDate?: boolean,
  isDirtyDeactivationDate?: boolean,
) =>
  object({
    firstName: requiredStringSchema,
    lastName: requiredStringSchema,
    dateOfBirth: isEmployeeIdOrg
      ? requiredDateSchema.max(today(), 'form.errors.invalidDateOfBirthFuture')
      : dateSchema.max(today(), 'form.errors.invalidDateOfBirthFuture'),
    province: requiredStringSchema,
    communicationEmail: emailSchema,
    activationDate: isDirtyActivationDate
      ? requiredDateSchema
          .min(today(), 'form.errors.invalidDateEligibilityActivation')
          .min(activationMinDate, 'form.errors.invalidDateBillingEligibilityActivation')
      : dateSchema,
    deactivationDate: isDirtyDeactivationDate
      ? dateSchema.when('activationDate', (activationDate) =>
          requiredDateSchema.min(activationDate, 'form.errors.invalidDateEligibilityDeactivation'),
        )
      : dateSchema,
  })

type MemberSchema = InferType<ReturnType<typeof memberSchema>>

const { region } = getApplicationConfig()
const adminAreaKey = AdminAreaTrKeys[region]

export const Profile: React.FC<ProfileProps> = ({
  eligibilityRecord,
  organizationType,
  organisationName,
}) => {
  const {
    t,
    i18n: { resolvedLanguage = 'en' },
  } = useTranslation()
  const tf = (field: RecordFieldType): string => t(`eligibilityRecordPage.fields.${field}`)

  const [recordStatusDialog, setRecordStatusDialog] = useState(false)
  const { attributes, status, eligibleIntervals } = eligibilityRecord
  const lastInterval = eligibleIntervals[eligibleIntervals.length - 1]

  const [openResendEmailDialog, setOpenResendEmailDialog] = useState(false)
  const [isViewMode, setViewMode] = useState(true)
  const [actionType, setActionType] = useState(ActionType.DEACTIVATE)
  const [viewOptionalAttributes, setViewOptionalAttributes] = useState<boolean>(false)
  const [submitDirective] = useSubmitEligibilityDirectiveMutation({
    fixedCacheKey: 'eligibilityRecordUpdate',
  })
  const { organizationId } = useParams() as OrganizationRoute
  const { data: organization } = useLegacyGetOrganizationQuery(organizationId)
  const userPermissions = useUserPermissions()
  const organizationBillingStartDate = organization?.billingStartDate || null
  const activationMinDate = getEarliestActivationDate(organizationBillingStartDate, userPermissions)
  const canSeeSecondaryAttributes = userPermissions?.includes(ScribePermission.MANAGE_ORGANIZATIONS)
  const canResendInvitation = Boolean(
    userPermissions?.includes(ScribePermission.RESEND_INVITATION) && !eligibilityRecord.participant,
  )
  const isEmployeeIdOrg = organizationType === MemberIdType.EMPLOYEE_ID
  const {
    uniqueIdentifier,
    firstName,
    lastName,
    province,
    dateOfBirth,
    postalCode,
    enrolmentId,
    communicationEmail,
    ...optionalAttributes
  } = attributes
  const pageTitle =
    firstName && lastName
      ? t('eligibilityRecordPage.title.named', { firstName, lastName })
      : t('eligibilityRecordPage.title.anonymous')

  const { data: availableAreas = [] } = useGetAdminAreasQuery(region)

  const formMethods: UseFormReturn<MemberSchema> = useForm<MemberSchema>({
    resolver: async (data, context, options) => {
      const { activationDate, deactivationDate } = formMethods.formState.dirtyFields
      const deactivationDateFieldHasValue = formMethods.getValues('deactivationDate') !== null
      return yupResolver(
        memberSchema(
          isEmployeeIdOrg,
          activationMinDate,
          activationDate,
          deactivationDate && deactivationDateFieldHasValue,
        ),
      )(data, context, options)
    },
  })

  const {
    getValues,
    handleSubmit,
    formState: { isDirty },
    reset,
    setValue,
    watch,
  } = formMethods

  useEffect(() => {
    const isFuture =
      status === EligibilityRecordStatus.INACTIVE && fromISOdate(lastInterval.startDate) > today()
    if (status === EligibilityRecordStatus.ACTIVE || isFuture) {
      setActionType(ActionType.DEACTIVATE)
    } else {
      setActionType(ActionType.REACTIVATE)
    }
  }, [status, lastInterval])

  useEffect(() => {
    const defaultValues = {
      firstName: attributes.firstName,
      lastName: attributes.lastName,
      dateOfBirth: attributes.dateOfBirth ? fromISOdate(attributes.dateOfBirth) : null,
      province: attributes.province,
      communicationEmail: attributes.communicationEmail,
      activationDate: lastInterval.startDate ? fromISOdate(lastInterval.startDate) : null,
      deactivationDate: lastInterval.endDate ? fromISOdate(lastInterval.endDate) : null,
      postalCode: attributes.postalCode,
      enrolmentId: attributes.enrolmentId,
    }
    reset(defaultValues)
  }, [attributes, reset, lastInterval])

  const onSubmit = useCallback(
    (params: MemberSchema) => {
      if (!isEmployeeIdOrg) {
        // Avoid overwriting/removing existing communication_email for email_type org
        params.communicationEmail = attributes.communicationEmail
      }
      const body: EligibilityDirective = {
        uniqueIdentifier: uniqueIdentifier,
        firstName: params.firstName ?? '#',
        lastName: params.lastName ?? '#',
        dateOfBirth: params.dateOfBirth ? toISOdate(params.dateOfBirth) : '#',
        province: params.province ?? '#',
        communication_email: params.communicationEmail ?? '#',
        activationDate: params.activationDate ? toISOdate(params.activationDate) : '#',
        deactivationDate: params.deactivationDate ? toISOdate(params.deactivationDate) : '#',
      }
      return submitDirective({ organizationId, body })
        .unwrap()
        .then(() => {
          setViewMode(true)
          reset()
        })
    },
    [uniqueIdentifier, organizationId, isEmployeeIdOrg, attributes, submitDirective, reset],
  )
  const onCancel = useCallback(() => {
    setViewMode(true)
    reset()
  }, [setViewMode, reset])

  const onEdit = useCallback(() => {
    const deactivationDateField = getValues('deactivationDate')

    // if deactivation date is infinity, we want to change it to null on edit so as not to
    // render the date in the datepicker
    if (deactivationDateField && isInfinity(toISOdate(deactivationDateField))) {
      setValue('deactivationDate', null)
    }

    setViewMode(false)
  }, [setViewMode, getValues, setValue])

  const toggleOptionalAttributes = () => {
    setViewOptionalAttributes(!viewOptionalAttributes)
  }

  const [dateOfBirthDisplay, activationDateDisplay, deactivationDateDisplay] = watch([
    'dateOfBirth',
    'activationDate',
    'deactivationDate',
  ]).map((date) => (date ? formatDate(date, resolvedLanguage, 'long') : ''))

  const onOpenResendEmailDialog = () => setOpenResendEmailDialog(true)
  const onCloseResendEmailDialog = () => setOpenResendEmailDialog(false)

  return (
    <Stack mb={7}>
      {recordStatusDialog && (
        <UpdateStatusDialog
          actionType={actionType}
          eligibilityRecord={eligibilityRecord}
          onClose={() => setRecordStatusDialog(false)}
        />
      )}
      <ResendEmailDialog
        open={openResendEmailDialog}
        onClose={onCloseResendEmailDialog}
        record={eligibilityRecord}
      />
      <Typography variant="h1" sx={{ mb: 4 }}>
        {isViewMode ? pageTitle : t('eligibilityRecordPage.title.editing', { title: pageTitle })}
      </Typography>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
        <Typography variant="h2" sx={{ mb: 0 }}>
          {t('eligibilityRecordPage.subtitle.memberDetails')}
        </Typography>
        {isViewMode ? (
          <Stack direction="row" spacing={2}>
            {canResendInvitation && (
              <Button variant="outlined" onClick={onOpenResendEmailDialog}>
                {t('resendWelcomeEmail.actionLabel')}
              </Button>
            )}
            <Button
              color={actionType === ActionType.REACTIVATE ? 'success' : 'error'}
              variant="contained"
              onClick={() => {
                setRecordStatusDialog(true)
              }}
              data-testId="button-action-type"
            >
              {t(`eligibilityRecordPage.buttons.${actionType}`)}
            </Button>
            <Button variant="contained" onClick={onEdit}>
              {t('eligibilityRecordPage.buttons.edit')}
            </Button>
          </Stack>
        ) : (
          <Box>
            <Button variant="outlined" onClick={onCancel} sx={{ mx: 1.5 }}>
              {t('global.dialog.cancel')}
            </Button>
            <Button
              onClick={handleSubmit(onSubmit)}
              key="preventDoubleSubmit"
              variant="contained"
              color="primary"
              disabled={!isDirty}
            >
              {t('global.dialog.submit')}
            </Button>
          </Box>
        )}
      </Box>
      <Card>
        <FormProvider {...formMethods}>
          <CardContent
            sx={{ p: 3 }}
            style={{ position: 'relative' }}
            component="form"
            noValidate
            data-testid="edit-member-form"
          >
            <Grid container spacing={1} columnSpacing={4} alignItems="center">
              <Grid item xs={12} md={6} xxl={5}>
                <AttributeRow label={tf('uniqueIdentifier')} value={uniqueIdentifier} />
              </Grid>
              <Grid item xs={12} md={6}>
                <AttributeRow
                  label={tf('dateOfBirth')}
                  value={
                    <DatePickerFormFieldWithViewMode
                      label={tf('dateOfBirth')}
                      isViewMode={isViewMode}
                      displayName={dateOfBirthDisplay}
                      variant="outlined"
                      name="dateOfBirth"
                      size="small"
                      maxDate={today()}
                      hideLabel
                    />
                  }
                />
              </Grid>
              <Grid item xs={12} md={6} xxl={5}>
                <AttributeRow
                  label={tf('firstName')}
                  value={
                    <InputFormFieldWithViewMode
                      label={tf('firstName')}
                      isViewMode={isViewMode}
                      variant="outlined"
                      name="firstName"
                      hideLabel
                    />
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <AttributeRow
                  label={tf('activationDate')}
                  value={
                    <DatePickerFormFieldWithViewMode
                      label={tf('activationDate')}
                      isViewMode={isViewMode}
                      displayName={activationDateDisplay}
                      variant="outlined"
                      name="activationDate"
                      minDate={activationMinDate}
                      openTo="month"
                      size="small"
                      hideLabel
                    />
                  }
                />
              </Grid>
              <Grid item xs={12} md={6} xxl={5}>
                <AttributeRow
                  label={tf('lastName')}
                  value={
                    <InputFormFieldWithViewMode
                      label={tf('lastName')}
                      isViewMode={isViewMode}
                      variant="outlined"
                      name="lastName"
                      hideLabel
                    />
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <AttributeRow
                  label={tf('deactivationDate')}
                  value={
                    <DatePickerFormFieldWithViewMode
                      label={tf('deactivationDate')}
                      isViewMode={isViewMode}
                      displayName={deactivationDateDisplay}
                      variant="outlined"
                      name="deactivationDate"
                      minDate={activationMinDate}
                      openTo="month"
                      size="small"
                      hideLabel
                    />
                  }
                />
              </Grid>
              <Grid item xs={12} md={6} xxl={5}>
                <AttributeRow
                  label={tf(adminAreaKey)}
                  value={
                    <SelectFormFieldWithViewMode
                      label={tf(adminAreaKey)}
                      isViewMode={isViewMode}
                      name="province"
                      variant="outlined"
                      options={availableAreas.map(({ isoCode, name }) => ({
                        label: getProvinceName(isoCode, name, t),
                        value: isoCode,
                      }))}
                      hideLabel
                    />
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <AttributeRow
                  label={tf('status')}
                  value={
                    <Typography variant="body2">
                      <Label
                        type={
                          status === EligibilityRecordStatus.ACTIVE
                            ? LabelType.DEFAULT
                            : LabelType.ARCHIVED
                        }
                        text={t(`global.eligibilityRecordStatus.${status}`)}
                        textProps={{ px: 2, py: 0.5, borderRadius: 10 }}
                        boxProps={{ mt: 0 }}
                      />
                    </Typography>
                  }
                />
              </Grid>
              <Grid item xs={12} md={6} xxl={5}>
                <AttributeRow label={tf('organization')} value={organisationName} />
              </Grid>
              {enrolmentId && canSeeSecondaryAttributes && (
                <Grid item xs={12} md={6}>
                  <AttributeRow label={tf('enrolmentId')} value={enrolmentId} />
                </Grid>
              )}
              {postalCode && canSeeSecondaryAttributes && (
                <Grid item xs={12} md={6} xxl={5}>
                  <AttributeRow label={tf('postalCode')} value={postalCode} />
                </Grid>
              )}
              <Grid item xs={12} md={6}>
                {isEmployeeIdOrg && (
                  <AttributeRow
                    label={tf('communicationEmail')}
                    value={
                      <InputFormFieldWithViewMode
                        label={tf('communicationEmail')}
                        isViewMode={isViewMode}
                        variant="outlined"
                        name="communicationEmail"
                        hideLabel
                      />
                    }
                  />
                )}
              </Grid>
            </Grid>
            {Object.keys(optionalAttributes).length > 0 && canSeeSecondaryAttributes && (
              <Box mt={2}>
                {viewOptionalAttributes && (
                  <Grid marginTop={-2} container spacing={1} columnSpacing={4} alignItems="center">
                    {Object.entries(optionalAttributes).map(([key, value], i) => (
                      // XXL = 5 when attribute is on the left (even)
                      <Grid item xs={12} md={6} xxl={i % 2 === 0 ? 5 : 6}>
                        <AttributeRow label={key} value={value} />
                      </Grid>
                    ))}
                  </Grid>
                )}
                <Button
                  variant="link"
                  onClick={toggleOptionalAttributes}
                  style={{ position: 'absolute', bottom: 10, right: 10 }}
                >
                  {viewOptionalAttributes
                    ? t('eligibilityRecordPage.buttons.hideAdditionalAttributes')
                    : t('eligibilityRecordPage.buttons.viewAdditionalAttributes')}
                </Button>
              </Box>
            )}
          </CardContent>
        </FormProvider>
      </Card>
    </Stack>
  )
}
