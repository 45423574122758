import { REGIONS } from 'app/lib/constants'
import { colors } from 'app/theme'

import type { Config } from '.'

export const testConfig = Object.freeze<Config>({
  auth0: {
    audience: '',
    clientId: '',
    domain: 'auth-test.co',
    connections: {
      cognito: 'cognito-test',
      okta: 'okta-test',
      adp: 'adp-test',
    },
  },
  launchDarkly: {
    clientId: 'test-key',
  },
  intercom: {
    appId: '',
  },
  scribe: {
    baseUrl: 'https://scribe-test.co',
  },
  multipass: {
    baseUrl: 'https://multipass-test.co',
  },
  coredata: {
    baseUrl: 'https://coredata-test.co',
  },
  activeMinutes: {
    baseUrl: 'https://active-minutes-test.co',
  },
  stripePublicKey: '',
  theme: {
    applicationBar: {
      backgroundColor: colors.dialogueCharcoal,
    },
  },
  assets_url: 'https://assets.dialoguecorp.com/maestro/dev',
  snowplow: {
    appId: 'presto',
    namespace: 'dev-ca2',
    endpoint: 'https://sp.dev.test.com',
  },
  region: REGIONS.CANADA,
})
